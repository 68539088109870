// import lottie from 'lottie-web'
// import * as animationData from './animation/data.json'
import $ from 'jquery'

$(document).ready(function() {
  // dhsv_vc_hero_section()
})

// function dhsv_vc_hero_section() {
//   var divContainer = document.getElementById('lottie-animation')
//   var params = {
//     container: divContainer,
//     renderer: 'svg',
//     loop: true,
//     autoplay: false,
//     animationData: animationData.default,
//   }
//   var animation = lottie.loadAnimation(params)
//
//   startAnim()
//
//   function startAnim() {
//     animation.setSpeed(0.75)
//     animation.play()
//   }
// }
