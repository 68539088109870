function smooth_scroll(link, e) {
  if (e) e.preventDefault()
  var hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }
  if (hash) {
    $('html, body').animate(
      {
        scrollTop: $(hash).offset().top,
      },
      600
    )
    history.replaceState(null, null, hash)
  }
  $(window).trigger('hashchange')
}

function resizeFullWidthRow() {
  $('[data-vc-full-width="true"]').each(function() {
    var offsetCalc
    if (window.matchMedia('(min-width: 992px)').matches) {
      offsetCalc = $('.calc-fullwidth-js').outerWidth() + 15
    } else {
      offsetCalc = 15
    }
    $(this)
      .css({
        width: document.body.clientWidth,
        marginLeft: -offsetCalc,
      })
      .addClass('strached')
    if (!$(this).attr('data-vc-stretch-content')) {
      let padding =
        (document.body.clientWidth - $('.container-fluid.wrap').outerWidth()) /
        2
      $(this).css({
        'padding-left': padding,
        'padding-right': padding,
      })
    }
  })
}

$('.toggle').on('click', function() {
  $(this).toggleClass('open-toggle')
  $('.navbar-collapse').fadeToggle()
  $('html').toggleClass('html-active')
})

$('.nav-link').on('click', function() {
  if ($('.toggle').hasClass('open-toggle')) {
    $('.toggle').toggleClass('open-toggle')
    $('.navbar-collapse').fadeToggle()
    $('html').toggleClass('html-active')
  }
})

const bannerInit = () => {
  $(document).ready(function() {
    $('.js-banner-left__toggler').on('click', function() {
      $(this)
        .closest('.optional-banner')
        .toggleClass('closed')
      window.localStorage.setItem('closedLeftBanner', true)
    })
  })

  const $leftBaner = $('.js-left-banner')

  if (!window.localStorage.getItem('closedLeftBanner')) {
    $leftBaner.removeClass('closed')

    // $(window).on('scroll', function() {
    //   if ($(window).scrollTop() > 0 && !$leftBaner.hasClass('closed')) {
    //     $leftBaner.addClass('closed')
    //   }
    // })
  }
}

export default {
  init() {
    bannerInit()
    // JavaScript to be fired on all pages
    $(window).load(function() {
      var lastId,
        topMenu = $('.sticky-navigation'),
        topMenuHeight = topMenu.outerHeight() + 1,
        menuItems = topMenu.find('li:not(.wpml-ls-item) a'),
        scrollItems = menuItems.map(function() {
          var item = $($(this).attr('href'))
          if (item.length) {
            return item
          }
        })

      $(window).scroll(function() {
        var fromTop = $(this).scrollTop() + topMenuHeight
        var cur = scrollItems.map(function() {
          if ($(this).offset().top < fromTop) return this
        })
        cur = cur[cur.length - 1]
        var id = cur && cur.length ? cur[0].id : ''
        if (lastId !== id) {
          lastId = id
          menuItems
            .parent()
            .removeClass('active')
            .end()
            .filter("[href='#" + id + "']")
            .parent()
            .addClass('active')
        }
      })

      /* eslint-disable */
      $(
        'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-tabs], [data-vc-accordion])'
      ).on('click', function(e) {
        smooth_scroll($(this).attr('href'), e)
      })
      /* eslint-enable */
      if (window.location.hash) {
        smooth_scroll(window.location.hash)
      }
    })

    resizeFullWidthRow()

    $(window).on('resize', function() {
      resizeFullWidthRow()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
