import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_news()
})

function dhsv_vc_news() {
  $('.dhsv_vc_news').each(function() {
    let loaded = false
    const ajax = window.ajax
    $('.js-button').click(function(e) {
      e.preventDefault()
      if (!loaded) {
        loaded = true
        let button = $(this)
        let container = button.closest('.news').find('.news-container')
        let newsCount = container.data('news')
        let newsPerPage = container.data('per-page')
        let newsLoaded = container.find('.news-item').length + 1
        $.post(ajax.url, {
          action: 'load_more',
          offset: newsLoaded,
          perpage: newsPerPage,
        }).success(function(response) {
          container.append(response)
          loaded = false
          if (newsCount <= newsLoaded + newsPerPage) {
            button.hide()
          }
        })
      }
    })
  })
}
